import React from "react"
import { useHtmlParser } from "../../hooks/useHtmlParser"
import "./styles.scss"

export const PressRelease = ({ data, ind, isExpanded, toggleExpanded }) => {
  return (
    <div className="my-3 press">
      <h3 data-testid="headline" className="mb-3">
        {data.headline}
      </h3>
      <div className="article-border mb-2" />
      {data.subtitle &&
        <div className="subtitle">
          <p>{data.subtitle}</p>
        </div>
      }
      <div
        data-testid="content"
        className={`${isExpanded ? "expanded " : ""}article-content`}
      >
        {useHtmlParser(isExpanded ? data.content : data.preview)}
        {isExpanded && data.contact && (
          <div className="contact">
            <p data-testid="contact">Contact: {data.contact.name}</p>
            <p data-testid="position">{data.contact.position}</p>
            <p data-testid="phone">{data.contact.phone}</p>
            <p className="salesTransformer rewind" />
          </div>
        )}
      </div>
      <button
        onClick={() => toggleExpanded(ind)}
        className="typenex-btn mt-3"
      >
        {isExpanded ? "CLOSE" : "READ MORE"}
      </button>
    </div>
  )
}

export default PressRelease
